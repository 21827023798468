<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <a-card>
        <template slot="title">
          <!-- <strong>Export All</strong> -->
          <a-button class="btn btn-outline-dark ml-2" @click="req">Lakukan Export All Form</a-button>
        </template>
      </a-card>
    </div>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
export default {
  created () {
    var what = this.$route.meta.key
    // console.log('what', what)
    this.form = what
  },
  data() {
    return {
      form: null,
    }
  },
  methods: {
    async req() {
      // var res =
      await lou.getZip('exportzip')
    },
  },
}
</script>

<style>

</style>
